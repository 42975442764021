import React from 'react';
// import { Script } from 'gatsby';

export const wrapPageElement = ({ element }: { element: any }) => (
  <React.Fragment>
    {element}
    {/* HubSpot Embed Code */}
    {/* <Script
      type="text/javascript"
      id="hs-script-loader"
      async
      defer
      src="//js.hs-scripts.com/43842890.js"
      onLoad={(e) => console.log('load')}
      onError={() => console.log('error')}
    /> */}
  </React.Fragment>
);

export default wrapPageElement;
